UserPage.css
/* Estilos gerais */
.user-page-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
}
.user-content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}
.chat-panel {
    width: 350px;
    height: 90dvh;
    background-color: #334B90;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    right: 0;  /* Posiciona o painel à direita */
    padding: 15px;
    box-shadow: 2px 0 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}
.chat-panel.minimized {
    height: 50px;
    overflow: hidden;
}
.chat-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
}
.messages-container {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 15px;
    padding-right: 10px;
    font-size: 14px;
}
.chat-panel.minimized {
    height: 50px;
    overflow: hidden;
}
.message {
    margin-bottom: 15px;
    line-height: 1.5;
}
.minimize-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 18px;
    transition: transform 0.3s ease;
}
.minimize-btn:hover {
    transform: scale(1.1);
}
.sent {
    text-align: right;
    background-color: #4ecca3;
    padding: 8px;
    border-radius: 10px;
    color: #000000;
    max-width: 80%;
    margin-left: auto;
}
.received {
    background-color: #f1f1f1;
    padding: 8px;
    border-radius: 10px;
    color: #333;
    max-width: 80%;
    margin-right: auto;
}
.chat-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.chat-input {
    flex-grow: 1;
    padding: 8px;
    margin-right: 10px;
    border-radius: 20px;
    border: none;
    background-color: #444;
    color: #fff;
    font-size: 14px;
}
.send-button {
    background-color: #4ecca3;
    border: none;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.send-button:hover {
    background-color: #36b27e;
}
.chat-input:focus,
.send-button:focus {
    outline: none;
}
.tooltip {
    background-color: #36b27e;
    color: white;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: -35px; /* Ajuste para posicionar acima do botão */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    visibility: hidden; /* Torna invisível quando não é necessário */
}
.tooltip.show {
    visibility: visible; /* Torna o tooltip visível */
    opacity: 1; /* Aumenta a opacidade */
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.waiting-queue {
    background: #222;
    padding: 15px;
    border-radius: 12px;
    margin-top: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    font-family: Arial, sans-serif;
}
.waiting-queue h3 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    color: #f8f9fa;
}
.waiting-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.waiting-item {
    display: flex;
    align-items: center;
    background: #333;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    transition: background 0.3s ease-in-out;
}
.waiting-item:hover {
    background: #444;
}
.connect-btn {
    background: #28a745;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    transition: background 0.3s ease-in-out;
}
.connect-btn:hover {
    background: #218838;
}
.waiting-item span {
    flex-grow: 1;
    font-size: 14px;
    color: #ddd;
    word-break: break-all;
}
.empty-list {
    text-align: center;
    color: #bbb;
    font-size: 14px;
    font-style: italic;
}