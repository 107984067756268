.parent {
  width: 290px;
  height: 300px;
  perspective: 1000px;
}
.card-verde {
  height: 100%;
  border-radius: 50px;
  background: linear-gradient(135deg, #4a5fa0 0%, rgb(0, 119, 255) 100%);
  transition: all 0.5s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: rgba(0, 50, 100, 0) 40px 50px 25px -40px, rgba(0, 50, 100, 0.2) 0px 25px 25px -5px;
}
.glass {
  transform-style: preserve-3d;
  position: absolute;
  inset: 8px;
  border-radius: 55px;
  border-top-right-radius: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.349) 0%, rgba(255, 255, 255, 0.815) 100%);
  transform: translate3d(0px, 0px, 25px);
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  transition: all 0.5s ease-in-out;
}
.content {
  padding: 100px 60px 0px 30px;
  transform: translate3d(0, 0, 26px);
}
.content .title {
  display: block;
  color: dark;
  font-weight: 900;
  font-size: 20px;
}
.content .text {
  display: block;
  color: dark;
  font-size: 15px;
  margin-top: 20px;
}
.bottom {
  padding: 10px 12px;
  transform-style: preserve-3d;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate3d(0, 0, 26px);
}
.bottom .view-more {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: flex-end;
  transition: all 0.2s ease-in-out;
}
.bottom .view-more:hover {
  transform: translate3d(0, 0, 10px);
}
.bottom .view-more .view-more-button {
  background: none;
  border: none;
  color: #000000;
  font-weight: bolder;
  font-size: 12px;
}
.bottom .view-more .svg {
  fill: none;
  stroke: #000000;
  stroke-width: 3px;
  max-height: 15px;
}
.bottom .social-buttons-container {
  display: flex;
  gap: 10px;
  transform-style: preserve-3d;
}
.bottom .social-buttons-container .social-button {
  width: 30px;
  aspect-ratio: 1;
  padding: 5px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  border: none;
  display: grid;
  color: #000000;
  place-content: center;
  box-shadow: rgba(5, 71, 17, 0.5) 0px 7px 5px -5px;
  position: relative;
}
.bottom .social-buttons-container .social-button::after {
  content: attr(data-name);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  color: #000000;
  padding: 0px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.bottom .social-buttons-container .social-button:hover::after {
  opacity: 1;
}
.bottom .social-buttons-container .social-button .svg {
  width: 15px;
  fill: #000000;
}
.bottom .social-buttons-container .social-button:hover {
  background: rgb(223, 233, 228);
}
.bottom .social-buttons-container .social-button:hover .svg {
  fill: white;
}
.bottom .social-buttons-container .social-button:active {
  background: rgb(255, 234, 0);
}
.bottom .social-buttons-container .social-button:active .svg {
  fill: #000000
}
.logo {
  position: absolute;
  right: 0;
  top: 0;
  transform-style: preserve-3d;
}
.logo .circle {
  display: block;
  position: absolute;
  aspect-ratio: 1;
  border-radius: 50%;
  top: 0;
  right: 0;
  box-shadow: rgba(100, 100, 111, 0.2) -10px 10px 20px 0px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(0, 119, 255, 0.2);
  transition: all 0.5s ease-in-out;
}
.logo .circle1 {
  width: 170px;
  transform: translate3d(0, 0, 20px);
  top: 8px;
  right: 8px;
}
.logo .circle2 {
  width: 140px;
  transform: translate3d(0, 0, 40px);
  top: 10px;
  right: 10px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  transition-delay: 0.4s;
}
.logo .circle3 {
  width: 110px;
  transform: translate3d(0, 0, 60px);
  top: 17px;
  right: 17px;
  transition-delay: 0.8s;
}
.logo .circle4 {
  width: 80px;
  transform: translate3d(0, 0, 80px);
  top: 23px;
  right: 23px;
  transition-delay: 1.2s;
}
.logo .circle5 {
  width: 50px;
  transform: translate3d(0, 0, 100px);
  top: 30px;
  right: 30px;
  display: grid;
  place-content: center;
  transition-delay: 1.6s;
}
.logo .circle5 .svg {
  width: 20px;
  fill: white;
}
.parent:hover .card-verde {
  transform: rotate3d(1, 1, 0, 30deg);
  box-shadow: rgba(5, 71, 17, 0.3) 30px 50px 25px -40px, rgba(5, 71, 17, 0.1) 0px 25px 30px 0px;
}
.parent:hover .card-verde .bottom .social-buttons-container .social-button {
  transform: translate3d(0, 0, 50px);
  box-shadow: rgba(5, 71, 17, 0.2) -5px 20px 10px 0px;
}
.parent:hover .card-verde .logo .circle2 {
  transform: translate3d(0, 0, 60px);
}
.parent:hover .card-verde .logo .circle3 {
  transform: translate3d(0, 0, 80px);
}
.parent:hover .card-verde .logo .circle4 {
  transform: translate3d(0, 0, 100px);
}
.parent:hover .card-verde .logo .circle5 {
  transform: translate3d(0, 0, 120px);
}