/* Efeito de rotação 3D horizontal */
.icon-rotate {
  display: inline-block;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d; /* Necessário para o efeito 3D */
}
.icon-rotate:hover {
  transform: rotateY(360deg); /* Rotação 3D horizontal */
}
/* Efeito de rotação 3D horizontal */
.icon-rotate {
  display: inline-block;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d; /* Necessário para o efeito 3D */
}
.icon-rotate:hover {
  transform: rotateY(360deg); /* Rotação 3D horizontal */
}
/* Efeito de vibração */
.icon-vibrate {
  display: inline-block;
  transition: transform 0.1s ease-in-out;
}
.icon-vibrate:hover {
  animation: vibrate 0.8s ease 1, wait 1s 1; /* 1 vez com espera de 2 segundos após */
}
@keyframes vibrate {
  0% { transform: translateX(0); }
  20% { transform: translateX(-4px); }
  40% { transform: translateX(4px); }
  60% { transform: translateX(-4px); }
  80% { transform: translateX(4px); }
  100% { transform: translateX(0); } /* Pausa no final */
}
/* Pausa entre animações */
@keyframes wait {
  0% { transform: translateX(0); }
}