/* Estilos para as bolinhas de progresso */
  /* Estilo para os ícones de progresso */
  .progress-icon {
    font-size: 36px;
    color: #6520ff;
    display: inline-block; /* Impede que a div cresça ao redor do ícone */
    position: relative; /* Para que o ícone se expanda sem afetar o layout */
    z-index: 10; /* Garante que o ícone apareça sobre os outros elementos */
    transition: transform 0.3s ease-in-out; /* Adiciona uma transição suave */
  }
  /* Definindo a animação para o ícone com hover */
  @keyframes iconHover {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.3);
    }
  }
  /* Animação de aumento do ícone com hover */
  .progress-icon:hover {
    animation: iconHover 0.3s ease-in-out forwards;
  }
  /* Estilo para os ícones "Arrived" */
  .progress-icon[style*="color: #cccccc"] {
    color: #cccccc;
  }
  /* Prevenir que o container da coluna cresça junto com os ícones */