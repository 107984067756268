.maintenance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(45deg, #334B90, #4CAF50);
    position: relative;
    overflow: hidden;
  }
  .maintenance-content {
    text-align: center;
    z-index: 2;
    color: #fff;
  }
  .maintenance-title {
    font-size: 3rem;
    font-weight: bold;
    margin-top: 20px;
  }
  .maintenance-message {
    font-size: 1.5rem;
    margin-top: 15px;
  }
  .spinner-container {
    margin-top: 30px;
  }
  .maintenance-icon {
    color: #fff;
  }
  .background-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://www.example.com/animation.gif'); /* Imagem ou animação para fundo */
    background-size: cover;
    opacity: 0.1;
    z-index: 1;
  }
  .back-button {
    margin-top: 40px;
  }