.card_box {
  width: 300px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: all .3s;
}
.card_box:hover {
  transform: scale(0.9);
}
.card_box header {
  position: absolute;
  width: 140px;
  height: 140px;
  top: 2px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow: hidden;
}
.card_box header::before {
  content: '';
  position: absolute;
  width: 250%;
  height: 40px;
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
  transform: rotate(-45deg) translateY(-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.card_box header::after {
  content: '';
  position: absolute;
  width: 5px;
  bottom: -1px;
  left: 0;
  height: 10px;
  z-index: -1;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
}
/* Novo quadrado centralizado */
.center-square {
  position: absolute;
  width: 5px; /* Ajuste o tamanho conforme necessário */
  height: 5px; /* Ajuste o tamanho conforme necessário */
  background: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
  top:2px; /* Ajusta para centralizar verticalmente */
  left: 135px; /* Ajusta para centralizar horizontalmente */
  z-index: -1; /* Garante que o quadrado esteja acima de outros elementos */
  border-radius: 5px; /* Opcional: Adiciona borda arredondada */
}
/* Define o estilo do texto da promoção */
.promo-label {
  position: relative;
  width: 250%;
  transform: rotate(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  bottom:15px; /* Ajusta para centralizar verticalmente */
  right: 15px; /* Ajusta para centralizar horizontalmente */
  text-shadow: 0.5px 0 0 currentColor, -0.5px 0 0 currentColor; /* Simula bold */
  letter-spacing: 0.1em;
}