.battery-container {
    display: flex;
    align-items: center;
    gap: 5px; /* Espaço entre os "piques" */
    background: linear-gradient(145deg, #dcdcdc, #f5f5f5); /* Efeito 3D leve */
    padding: 12px;
    border-radius: 20px;
    position: relative;
    width: fit-content;
    box-shadow: inset 4px 4px 8px #bebebe, inset -4px -4px 8px #ffffff; /* Sombra interna */
}
.battery-tip {
    width: 12px;
    height: 35px;
    background: #666;
    border-radius: 8px;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.nossos-numeros {
    background: linear-gradient(145deg, #2ecc71, #27ae60); /* Verde com gradiente bonito */
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: dark;
    font-weight: bold;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    /* Borda inclinada */
    clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.nossos-numeros:hover {
    transform: scale(1.05);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}