/* Adicionando a animação de vibração ao ícone */
.product-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 16px;
  transition: transform 0.3s ease, box-shadow 0.4s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  border: none;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}
.product-card:hover {
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);
}
.product-card img {
  width: 100%;
  height: 50px;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.4s ease;
}
.product-card-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
  gap: 2px;
  transition: all 0.3s ease;
  bottom: 0
}
.product-card-description {
  font-size: 14px;
  text-align: justify;
  padding: 5px;
  color: #777;
  margin-top: 10px;
  transition: color 0.3s ease;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limita a 2 linhas */
  -webkit-box-orient: vertical;
  /* Define a orientação da caixa */
  overflow: hidden;
  /* Esconde o texto que excede o limite */
  text-overflow: ellipsis;
  /* Adiciona reticências '...' no final do texto que é cortado */
}
.product-card-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Limita a 2 linhas */
  -webkit-box-orient: vertical;
  /* Define a orientação da caixa */
  overflow: hidden;
  /* Esconde o texto que excede o limite */
  text-overflow: ellipsis;
  /* Adiciona reticências '...' no final do texto que é cortado */
  text-align: left;
  padding-left: 5px;
}
.product-sidebar-title{
  font-weight: 800;
}
.product-card:hover .product-card-title,
.product-card:hover .product-card-description {
  color: #02219f;
  cursor: pointer;
}
@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Aplicando o efeito de vibração aos ícones */
.product-card button:hover .anticon {
  animation: vibrate 0.5s ease-in-out;
}